import logoUni from '../../assets/images/unpsjb.png';
import argentinaPrograma from '../../assets/images/argentinaPrograma.png';
import sayQuality from '../../assets/images/sayQuality.png';
import miniterioEconomia from '../../assets/images/ministerioEconomia.png';
import logo from '../../assets/images/about-me_.png'; 

import aridosCardenas from '../../assets/images/aridosCardenas.jpeg'; 


const educationData  = [

    {  
        title: "Analista Programador Universitario",
        description: "Universidad Nacional de la Patagonia San Juan Bosco",
        year: "2024",
        image: logoUni,
    },

    {  
        title: "Programador Full Stack",
        description: "Argentina Programa",
        year: "2021",
        image: argentinaPrograma,
    },

    {  
        title: "Tester QA Manual",
        description: "Say Quality",
        year: "2021",
        image: sayQuality,
    }
];

const experienceData  = [

    {  
        title: "Analista Programador",
        description: "Ministerio de Economía, Provincia del Chubut, Argentina",
        rol: " Desarrollé programas en Java para automatizar los procesos de liquidación de sueldos, lo que optimizó la eficiencia interna. Además, creé una aplicación web en Python utilizando Django para gestionar documentos relacionados con sueldos, préstamos y reclamos, facilitando el acceso tanto a mutuales como al personal interno.",
        year: "2023-2024",
        image: miniterioEconomia,
    },

    {  
        title: "Consultor IT y Voluntario",
        description: "Servicios de IT durante viajes en Perú y Bolivia",
        rol: " Realicé el mantenimiento de equipos y brindé capacitación al personal en prácticas digitales. Además, asesoro en el diseño y planificación de aplicaciones para centros hoteleros, así como en la implementación de infraestructura IT para un hostal.",
        year: "07/2024 - 10/2024",
        image: logo,
    },

    {  
        title: "Soporte Técnico",
        description: "Áridos Cárdenas - Ancud, Chiloé, Chile",
        rol: " Mantuve el equipo informático y proporcioné soporte al personal. Además, desarrollé planillas automatizadas para controlar el stock y las ventas, lo que optimizó la gestión de inventarios y las operaciones de la empresa.",
        year: "12/2021 - 03/2023",
        image: aridosCardenas,
    },

];

export { educationData, experienceData };