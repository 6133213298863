import React from 'react';
import './index.scss';
import logo from '../../assets/images/about-me_.png'; 
import personal from '../../assets/images/personal.jpeg'; 
import Footer from '../Footer'; // Importa tu componente Footer

import { educationData, experienceData } from './trajectoria';

const TrajectoryItem = ({ title, description, year, rol, image, width }) => (
    <div className="trajectory-item">
        <div className="text-container trajectoryTitle">
            {title}
            <p className="trajectoryDescription">
                {description} | {year}
            </p>
            <p>
                {rol}
                </p>
        </div>
        <div className="image-container">
            <picture>
                <source srcSet={image} type="image/avif" />
                <source srcSet={image.replace('.avif', '.webp')} type="image/webp" />
                <img src={image.replace('.avif', '.jpg')} alt={title} className="trajectory-image" style={{ width: width }} />
            </picture>
        </div>
    </div>
);


const Trayectory = () => {
    return (
        <div>
        <div className="home-container">
    <div className="image-content center-content">
    <div className="photoPersonal">
            <picture>
                <source srcSet={personal} type="image/avif" />
                <source srcSet={personal.replace('.avif', '.webp')} type="image/webp" />
                <img width="300" height="300" src={personal.replace('.avif', '.jpg')} alt="Martin disfrutando de la playa con su perrita" />
            </picture>
        </div>
        <br/>
        <br/>
        <div className="logo-container photoPersonal">
            <picture>
                <source srcSet={logo} type="image/avif" />
                <source srcSet={logo.replace('.avif', '.webp')} type="image/webp" />
                <img width="400" height="400" src={logo.replace('.avif', '.jpg')} alt="Computadora" />
            </picture>
        </div>

    </div>

    <div className="text-content">
        <h1>TRAYECTORIA</h1>
        <p>Con una profunda pasión por la tecnología, tengo una trayectoria que abarca tanto proyectos independientes como experiencia en el sector público, incluyendo mi labor en el Ministerio de Economía. Me motiva implementar soluciones innovadoras y optimizar procesos a través del desarrollo de software.</p>
        <p>En mi tiempo libre, disfruto de actividades al aire libre como correr, andar en bicicleta, acampar y compartir momentos con mi perrita. Estas actividades me ayudan a mantener un equilibrio saludable entre mi vida profesional y personal.</p>
        
        <h2>EDUCACIÓN</h2>
        {educationData.map((edu, index) => (
            <TrajectoryItem key={index} title={edu.title} description={edu.description} year={edu.year} image={edu.image} width="80px" />
        ))}

        <h2>EXPERIENCIA</h2>
        {experienceData.map((exp, index) => (
            <TrajectoryItem key={index} title={exp.title} description={exp.description} year={exp.year} rol={exp.rol} image={exp.image} width="80px" />
        ))}
    </div>

</div>
    <Footer />
</div>

    );
};

export default Trayectory;