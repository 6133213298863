import './index.scss';

const Footer = () => {
  return (
    <>
      <br /> {/* Salto de línea */}
      <footer className="footer">
      </footer>
    </>
  );
};

export default Footer;
