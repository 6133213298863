import ShotURL from '../../assets/images/shortURL.png';

import svg_JavaScript from '../../assets/images/icons8-javascript.svg'
import svg_React from '../../assets/images/icons8-reaccionar-nativo.svg'
import svg_npm from '../../assets/images/icons8-npm.svg'
import svg_nextJS from '../../assets/images/icons8-nextjs.svg'
import svg_mongoDB from '../../assets/images/icons8-mongodb.svg'
import svg_prisma from '../../assets/images/icons8-prisma-orm.svg'

const workData = [
  {
    image: ShotURL,
    title: "Acortador de URL",
    description: "Desarrollé una plataforma avanzada para acortar URLs que va mucho más allá de solo enlaces cortos. Creé un sistema que permite a los usuarios acceder mediante Google, GitHub o LinkedIn, con historial detallado, contador de redireccionamientos y un buscador para encontrar URLs fácilmente. Además, implementé una base de datos en clúster para asegurar estabilidad y control total de los enlaces, evitando duplicados y manteniendo todo organizado en un solo lugar.",
    githubLink: "https://github.com/cozakoo/url-short",
    technologies: [
      svg_npm,
      svg_nextJS,
      svg_React,
      svg_JavaScript,
      svg_prisma,
      svg_mongoDB,
    ],
  },
];
export default workData;