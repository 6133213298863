import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Alignment from './components/Alignment';
import Home from './components/Home';
import Projects from './components/Projects';
import Trajetory from './components/Trajetory';
import NotFound from './components/NotFound';
import Works from './components/Works';


function App() {
  return (
    <>
    <Routes>
        <Route path='/' element={<Alignment />}>
          <Route index element={<Home />} />
          <Route path='/trayectoria' element={<Trajetory/>} />
          <Route path='/projectos' element={<Projects />} />
          <Route path='/trabajos' element={<Works />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
