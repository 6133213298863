// projectData.js

import Sec2 from '../../assets/images/page-sec.png';
import sec2Video from '../../assets/images/SEC2.gif';

import AnalisisCreditoVideo from '../../assets/images/Analisis-de-credito.gif';
import AnalisisCredito from '../../assets/images/Analisis-de-credit.png';

import ControlTransacciones from '../../assets/images/control-transacciones.png';
import ControlTransaccionesVideo from '../../assets/images/controlTransacciones.gif';

import GestionMutuales from '../../assets/images/gestion-mutuales.png';
import GestionMutualesVideo from '../../assets/images/gestionMutuales.gif';

import MutualesWeb from '../../assets/images/mutualesWeb.png';



import svg_JavaScript from '../../assets/images/icons8-javascript.svg'
import svg_Python from '../../assets/images/icons8-python.svg'
import svg_Django from '../../assets/images/icons8-django.svg'
import svg_Boostrap from '../../assets/images/icons8-oreja.svg'
import svg_Postgres from '../../assets/images/icons8-postgres.svg'
import svg_Java from '../../assets/images/icons8-logotipo-de-java.svg'
import svg_Sqlite from '../../assets/images/icons8-sqlite.svg'
import svg_Docker from '../../assets/images/icons8-estibador.svg'


const workData = [
  {
    image: Sec2,
    title: "GESTIÓN INTEGRAL DE AFILIADOS, CURSOS Y EVENTOS",
    description: 'Desarrollé un sistema integral para la gestión de afiliados, cursos y eventos, diseñado específicamente para el Sindicato de Empleados de Comercio. Esta solución permite un control exhaustivo de datos, pagos y asistencias, garantizando una administración eficiente y segura. El sistema incluye funcionalidades avanzadas como el registro y seguimiento de usuarios, emisión de certificados con firma digital, y la recopilación de datos estadísticos que facilitan la toma de decisiones informadas. Así, se asegura un enfoque estratégico en la gestión de afiliados y actividades del sindicato.',  
    githubLink: "https://github.com/cozakoo/Python_Django_SistemaSEC",
    videoLink: sec2Video,
    technologies: [
      svg_Python,
      svg_Django,
      svg_Boostrap,
      svg_Postgres,
      svg_JavaScript,
    ],
  },

  {
    image: MutualesWeb,
    title: "SISTEMA WEB PARA GESTIÓN DE DECLARACIONES JURADAS",
    description: 'Desarrollé una plataforma web para el Ministerio de Economía que automatiza la carga de declaraciones juradas sobre préstamos y reclamos. Esta solución permite a múltiples usuarios, incluidas entidades mutuales, presentar declaraciones de manera concurrente. El sistema verifica formatos, almacena datos y mantiene un historial detallado de las presentaciones. Además, incorpora alertas para notificaciones y optimiza la precisión en el control de declaraciones, mejorando significativamente la experiencia del usuario y la gestión administrativa.',
    githubLink: "https://github.com/cozakoo/MutualesWeb_DjangoPython",
    technologies: [
      svg_Python,
      svg_Django,
      svg_Boostrap,
      svg_Postgres,
      svg_JavaScript,
      svg_Docker
    ]
  },

  {
    image: AnalisisCredito,
    title: "HERRAMIENTA DE COMPARACIÓN Y ANÁLISIS DE CRÉDITOS",
    description: 'Desarrollo de una herramienta para comparar archivos generados por un programa en COBOL, uno en formato .xls y otro en formato .csv, con el objetivo de analizar información relacionada con créditos. Esta solución automatiza un proceso que anteriormente se realizaba de forma casi manual, lo que ha llevado a un significativo incremento en la eficiencia tanto en términos de tiempo como de logística. La implementación de este software no solo optimiza el análisis de datos, sino que también permite una toma de decisiones más ágil y precisa.',
    githubLink: "https://github.com/cozakoo/Java_AnalisisDeCreditos",
    videoLink: AnalisisCreditoVideo,
    technologies: [
      svg_Java,
      svg_Sqlite,
    ]
  },

  {
    image: ControlTransacciones,
    title: "VERIFICACIÓN DE TRANSACCIONES AS/400",
    description: 'Desarrollo de un sistema que compara la salida de la AS/400, donde se detalla la información de las transacciones, y verifica el cumplimiento de un formato predeterminado. En caso de que las transacciones no se ajusten a dicho formato, el sistema genera alertas informativas. Esta solución permite detectar inconsistencias de manera eficiente, garantizando la calidad de los datos y facilitando su análisis.',
    githubLink: "https://github.com/cozakoo/Java_ControlTransacciones",
    videoLink: ControlTransaccionesVideo,
    technologies: [
      svg_Java,
      svg_Sqlite,
    ]
  },

  {
    image: GestionMutuales,
    title: "CARGA Y VALIDACIÓN DE DECLARACIONES",
    description: 'Desarrollo de un programa que automatiza la carga de declaraciones juradas con información de préstamos y reclamos desde archivos en formato de texto (.txt). El sistema verifica el cumplimiento de un formato establecido, almacena los datos procesados en una base de datos PostgreSQL compartida, y mantiene un historial detallado de cada operación realizada. Esta solución optimiza la precisión y eficiencia en el control de declaraciones, facilita la validación automática y garantiza la integridad de los datos, permitiendo un seguimiento exhaustivo de los registros procesados.',
    githubLink: "https://github.com/cozakoo/Java_SistemaGestionDeclaracionesMutuales",
    videoLink: GestionMutualesVideo,
    technologies: [
      svg_Java,
      svg_Postgres,
    ]
  },

];
export default workData;