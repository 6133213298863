import './index.scss';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
  return (
    <div className="nav-bar">
      <nav>
        <NavLink exact="true" activeclassname="active" to="/">
          <FontAwesomeIcon icon={faHome} color="#78A1D1" />
          <span className="nav-text">Inicio</span>
        </NavLink>

        <NavLink exact="true" activeclassname="active" className="trayectoria-link" to="/trayectoria">
          <FontAwesomeIcon icon={faBriefcase} color="#78A1D1" />
          <span className="nav-text">Trayectoria</span>
        </NavLink>

        <NavLink exact="true" activeclassname="active" className="projects-link" to="/projectos">
          <FontAwesomeIcon icon={faLightbulb} color="#78A1D1" />
          <span className="nav-text">Proyectos</span>
        </NavLink>


        <NavLink exact="true" activeclassname="active" className="projects-link" to="/trabajos">
          <FontAwesomeIcon icon={faFolder} color="#78A1D1" />
          <span className="nav-text">Trabajos</span>
        </NavLink>

      </nav>
    </div>
  )
}

export default Navbar;
