import './index.scss'

function NotFound() {
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p className="not-found-description">¡Ups! La página que estás buscando no existe.</p>
      <img width="100" height="100" src="https://img.icons8.com/plasticine/100/joker-movie.png" alt="joker-movie"/>
    </div>
  );
}

export default NotFound;