import React, { useEffect, useState } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import computer from '../../assets/images/hero_setup.avif'; 

const Home = () => {
    const [index, setIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false); // Control de visibilidad
    const phrases = [
        "Analista Programador & Desarrollador Web",
        '"No tengas miedo de fallar, ten miedo de no intentarlo"',
    ];
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsVisible(false); // Oculta el texto
            setTimeout(() => {
                setIndex(prevIndex => (prevIndex + 1) % phrases.length);
                setIsVisible(true); // Muestra el texto
            }, 500); // Tiempo para la transición de desvanecimiento
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="home-container home-container-logo">
            <div className="image-content hero-image">
                <picture>
                    <source srcSet={computer} type="image/avif" />
                    <source srcSet={computer.replace('.avif', '.webp')} type="image/webp" />
                    <img width="500" height="500" src={computer.replace('.avif', '.jpg')} alt="Computadora" />
                </picture>
            </div>

            <div className="text-content">
                <h1>BIENVENIDO</h1>
                <h2 className={isVisible ? 'visible' : ''}>{phrases[index]}</h2>   
                <p>
                Hola, soy Martin, un Analista Programador especializado en la optimización de procesos y el desarrollo de soluciones tecnológicas. Con una sólida experiencia en la creación de aplicaciones web y de escritorio, utilizo metodologías ágiles que me permiten adaptarme rápidamente a las necesidades específicas de cada proyecto. Mi compromiso y enfoque orientado a resultados me impulsan a buscar soluciones innovadoras y eficientes para los desafíos que enfrento.
                </p>
                <p>martinarcosvargas2@gmail.com</p> {/* Cambia este correo por el tuyo */}

                <div className="social-icons">
                    <a href="https://www.linkedin.com/in/martin-arcos/" target="_blank" rel="noopener noreferrer">
                        <img width="70" height="70" src="https://img.icons8.com/plasticine/100/linkedin.png" alt="linkedin"/>
                    </a>
                    
                    <a href="https://github.com/cozakoo" target="_blank" rel="noopener noreferrer">
                        <img width="70" height="70" src="https://img.icons8.com/plasticine/100/github-squared.png" alt="github-squared"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Home;
