import './index.scss';
import { FaGlobe, FaGithubSquare, FaVideo } from 'react-icons/fa';
import workData from './workData';
import Footer from '../Footer'; // Importa tu componente Footer

const Works = () => {
  
  return (
    <div className="works-container">
      <h1>TRABAJOS REALIZADOS</h1>
      <h2>Colección de trabajos que he realizado o colaborado</h2>

      <div className="works-grid">
        {workData.map((project, index) => (
        <div className="card" key={index}>
          <div className="image-container">
            <img src={project.image} alt={project.title} loading="lazy" className="card-image" />
            <div className="tech-overlay">
              {project.technologies.map((tech, techIndex) => (
              <img
                key={techIndex}
                src={tech}
                alt="Technology Icon"
                className="tech-icon"
              />
              ))}
            </div>
          </div>
        <div className="card-content">
          <h6 className="card-title">{project.title}</h6>
          <div className="icons">
            <a href={project.githubLink} target="_blank" rel="noreferrer">
              <FaGithubSquare />
            </a>
            {project.videoLink ? (
              <a href={project.videoLink} target="_blank" rel="noreferrer">
                <FaVideo />
              </a>
            ) : (
              project.liveLink && project.liveLink !== project.githubLink && (
                <a href={project.liveLink} target="_blank" rel="noreferrer">
                  <FaGlobe />
                </a>
              )
            )}
          </div>
          <p className="card-description">{project.description}</p>
        </div>
      </div>
    ))}
    </div>
    
    <Footer />
  
  </div>
);
};

export default Works;